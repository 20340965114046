import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const DownIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 256 256" width={48} height={48} {...props}>
    <polyline
      points="208 96 128 176 48 96"
      fill="none"
      stroke={COLORS.main}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
  </svg>
)

export default DownIcon
