import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyPage, IFaq } from "src/types"
import Layout from "src/layout"
import { Section, Subtitle, Title, Content } from "src/components/styled"
import Question from "src/components/Question"
import { SIZES, BREAKPOINTS } from "src/constants"

const Faq = styled.div`
  margin: 4em 0;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    margin: 3em 0;
  }
`
const SectionTitle = styled.h3`
  font-size: ${SIZES.large};
  margin-top: 2em;
  text-transform: uppercase;
`

const FaqPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Questions fréquentes">
      <Content>
        <Section>
          <Subtitle>QUESTIONS FRÉQUENTES</Subtitle>
          <Title>
            <span>Une question ? La réponse est ici.</span>
          </Title>
        </Section>
        <Faq>
          {data.categories.nodes.map(({ id, title, questions }: IFaq) => (
            <Section key={id}>
              <SectionTitle>{title}</SectionTitle>
              {questions.map((question) => (
                <Question key={question.id} question={question} />
              ))}
            </Section>
          ))}
        </Faq>
      </Content>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  query {
    categories: allContentfulFaq(sort: { fields: [position], order: ASC }) {
      nodes {
        id: contentful_id
        title
        questions {
          id: contentful_id
          title
          answer {
            json
          }
        }
      }
    }
  }
`
