import React, { useState } from "react"
import styled from "styled-components"

import { IQuestion } from "src/types"
import RichText from "src/components/RichText"
import { SIZES, COLORS } from "src/constants"
import DownIcon from "src/svg/Down"

const Container = styled.div`
  font-size: ${SIZES.normal};
  border-top: 2px solid ${COLORS.main};
  &:last-of-type {
    border-bottom: 2px solid ${COLORS.main};
  }
`
const ToggleButton = styled.button<{ open: boolean }>`
  display: block;
  width: 100%;
  padding: 0;
  text-align: inherit;
  svg {
    float: right;
    margin-top: 5px;
    transform: rotate(${({ open }) => (open ? "0" : "-90deg")});
  }
  & + p {
    margin-top: 0;
  }
`
const QuestionTitle = styled.h4`
  margin: 1em 0;
`

interface Props {
  question: IQuestion
}

const Question = ({ question }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <ToggleButton open={open} onClick={() => setOpen(!open)}>
        <DownIcon />
        <QuestionTitle>{question.title}</QuestionTitle>
      </ToggleButton>
      {open && <RichText node={question.answer} />}
    </Container>
  )
}

export default Question
